




















import { Component, Mixins, Vue, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import TestStudentHistoryHeader from '@/components/organisms/TestStudentHistoryHeader.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import StudentHistoryTotal from '@/components/organisms/StudentHistoryTotal.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    SidebarSwitcher,
    TestStudentHistoryHeader,
    ColoredBox,
    StudentHistoryTotal,
    SelectBase,
  },
})
export default class LearningSituation extends Mixins(ClassModeChangeAble) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private totalStatusDatas = {}

  private selectTestOptions: { text: string; value: string }[] = []

  private selectedTest = ''

  private hasTermDate = true

  @Watch('selectedTest')
  onSelectedTestChange(newTestDate: string) {
    if (newTestDate) {
      const [start, end, _] = newTestDate.split(',')
      if (start && end) {
        this.hasTermDate = true
        this.getResultSummaries({ start: start, end: end })
      } else {
        this.hasTermDate = false
      }
    }
  }

  private async created(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    this.getTermExamsTerms()
  }

  private async getTermExamsTerms() {
    Vue.prototype.$http.httpWithToken.get('/termExams/terms', { params: { userId: this.userId } }).then((res: any) => {
      if (res.data.length === 0) {
        this.hasTermDate = false
        return
      }
      this.selectTestOptions = res.data.map(
        (term: { gradeName: string; termName: string; from: string; to: string }, index: number) => {
          return { text: `${term.gradeName} ${term.termName}`, value: `${term.from},${term.to},${index}` }
        }
      )
      const selectedIndex = res.data.findIndex((term: { isSelected: boolean }) => {
        return term.isSelected === true
      })
      const selected = res.data[selectedIndex]
      this.selectedTest = `${selected.from},${selected.to},${selectedIndex}`
    })
  }

  private async getResultSummaries(params: { start: string; end: string }) {
    Vue.prototype.$http.httpWithToken
      .get(`/history/resultSummaries/${this.userId}`, {
        params: { classModeCode: 'TK', startAt: params.start, endAt: params.end },
      })
      .then((res: any) => {
        this.totalStatusDatas = FormatResultSummaries(res.data)
      })
  }
}
